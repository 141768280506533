<template>
    <div class="ads-container-wrapper" @mousemove="this.customPointerPosition" :style="getBackgroundImageStyle()">
        <div class="ad-img-large" :style="getLargeImageStyle()" @click="openLink(true)"></div>
        <div class="ad-img-small" :style="getSmallImageStyle()" @click="openLink(false)"></div>
        <div class="custom-cursor" ref="customCursor">Mehr erfahren</div>
    </div>
</template>

<script>
import {services} from "../../main";

export default {
    name: "LoginAd",
    props: {
        imageIndex: {
            type: Number,
            default: -1,
        },
    },
    data: function () {
        return {
            activeImage: {}
        }
    },
    computed: {
        images: function () {
            if (this.$features.WHITELABEL) {
                return [
                    {
                        background: ``,
                        backgroundColor: "black",
                        desktop: this.$whitelabelConfig.loginImage,
                        mobile: this.$whitelabelConfig.loginImage,
                    }
                ]
            }
            return [
                {
                    background: ``,
                    backgroundColor: "#333",
                    desktop: `https://company.platform.entwicklerheld.de/link/zoll-karriere-login-screen-desktop`,
                    mobile: `https://company.platform.entwicklerheld.de/link/zoll-karriere-login-screen-mobile`,
                    linkDesktop: "https://company.platform.entwicklerheld.de/link/zoll-karriere-login-screen-desktop-link",
                    linkMobile: "https://company.platform.entwicklerheld.de/link/zoll-karriere-login-screen-mobile-link",
                    target: "_blank"
                },
                {
                    background: ``,
                    backgroundColor: "#333",
                    desktop: `https://company.platform.entwicklerheld.de/link/zoll-karriere-login-screen-desktop`,
                    mobile: `https://company.platform.entwicklerheld.de/link/zoll-karriere-login-screen-mobile`,
                    linkDesktop: "https://company.platform.entwicklerheld.de/link/zoll-karriere-login-screen-desktop-link",
                    linkMobile: "https://company.platform.entwicklerheld.de/link/zoll-karriere-login-screen-mobile-link",
                    target: "_blank"
                },
                {
                    background: ``,
                    backgroundColor: "#fff",
                    desktop: `https://company.platform.entwicklerheld.de/link/bsi-banner-1-desktop-10-24`,
                    mobile: `https://company.platform.entwicklerheld.de/link/bsi-banner-1-mobile-10-24`,
                    linkDesktop: "https://company.platform.entwicklerheld.de/link/bsi-banner-link-10-24",
                    linkMobile: "https://company.platform.entwicklerheld.de/link/bsi-banner-link-10-24",
                    target: "_blank"
                },
                {
                    background: ``,
                    backgroundColor: "#fff",
                    desktop: `https://company.platform.entwicklerheld.de/link/bsi-banner-2-desktop-10-24`,
                    mobile: `https://company.platform.entwicklerheld.de/link/bsi-banner-2-mobile-10-24`,
                    linkDesktop: "https://company.platform.entwicklerheld.de/link/bsi-banner-link-10-24",
                    linkMobile: "https://company.platform.entwicklerheld.de/link/bsi-banner-link-10-24",
                    target: "_blank"
                }
            ];
        }
    },
    created() {
        this.activeImage = this.getRandomImage();
        if (this.images.length !== 1) {
          services.googleAnalytics.sendEvent("Login Ad", "shown", this.activeImage.link);
        }
    },
    methods: {
        openLink: function (isLarge) {
            const link = isLarge ? this.activeImage.linkDesktop : this.activeImage.linkMobile;
            localStorage.setItem("promotion", link);
            services.googleAnalytics.sendEvent("Login Ad", "clicked", link);
            if (this.activeImage.target) {
                window.open(link, this.activeImage.target);
                return;
            }
            window.location.href = link;
        },
        getRandomImage: function () {
            if (this.imageIndex !== -1 && this.imageIndex < this.images.length) {
              return this.images[this.imageIndex];
            }
            return this.images[Math.floor(Math.random() * this.images.length)];
        },
        getBackgroundImageStyle: function () {
            return {
                "background-image": `url("${this.activeImage.background}")`,
                "background-color": this.$features.WHITELABEL ? 'white' : `${this.activeImage.backgroundColor}`,
                "pointer-events": this.$features.WHITELABEL ? "none" : "all",
            }
        },
        getLargeImageStyle: function () {
            return {
                "background-image": `url("${this.activeImage.desktop}")`
            }
        },
        getSmallImageStyle: function () {
            return {
                "background-image": `url("${this.activeImage.mobile}")`
            }
        },
        customPointerPosition: function (event) {
            const customCursor = this.$refs.customCursor;
            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;
            if (event.clientX + 25 + 175 >= windowWidth || event.clientY + 50 >= windowHeight) {
                customCursor.style.display = 'none';
            } else {
                customCursor.style.display = '';
            }
            customCursor.style.top = event.clientY - 5 + "px";
            customCursor.style.left = event.clientX + 25 + "px";
        }
    },
}
</script>

<style scoped>
.ads-container-wrapper {
    width: 100%;
    background-color: black;
    display: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: all linear 1s;
}

.ads-container-wrapper:hover > .ad-img-large, .ad-img-small {
    cursor: alias;
}

.ad-img-large, .ad-img-small {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: none;
}

.ads-container-wrapper:hover > .custom-cursor {
    display: block;
}

.ads-container-wrapper:hover {
    background-image: none;
}

.ad-img-small {
    display: block;
}

.custom-cursor {
    position: absolute;
    text-transform: uppercase;
    text-shadow: 1px 1px 2px black;
    display: none;
    font-style: italic;
    font-size: 20px;
    font-weight: bold;
    color: whitesmoke;
}

@media (min-width: 500px) {
    .ads-container-wrapper {
        display: block;
    }
}

@media (min-width: 1380px) {
    .ad-img-small {
        display: none;
    }

    .ad-img-large {
        display: block;
    }
}

</style>
